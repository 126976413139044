<template>
  <div
    v-show="abrirVariaveis"
    style="background-color: white !important"
    :style="`width: ${retornaLarguraVariaveis()}px`"
  >
    <v-toolbar flat color="primary" dark>
      <v-toolbar-title>Variáveis disponíveis</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon @click="$emit('fecharVariaveis')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-list class="pa-0">
      <div>
        <v-text-field
          flat
          class="px-4"
          placeholder="Pesquisar"
          prepend-inner-icon="mdi-magnify"
          v-model="busca"
        ></v-text-field>
      </div>

      <v-divider></v-divider>

      <v-list-item-group
        class="ml-variavel-mensagem"
        :style="isEmail ? 'height: 67.5vh' : 'height: 65vh'"
      >
        <div v-for="variavel in variavelFiltrada" :key="variavel.id">
          <v-list-item
            three-line
            @click="concatenarVariavel(variavel)"
            inactive
            style="cursor: pointer"
          >
            <v-list-item-content>
              <v-list-item-title class="py-2">
                <v-chip
                  outlined
                  class="ml-nome-variavel font-weight-medium"
                  style="cursor: pointer !important"
                >
                  {{ variavel.nome }}
                </v-chip>
              </v-list-item-title>
              <v-list-item-subtitle class="mb-2" v-if="variavel.tamanho">
                {{ variavel.tamanho }} caracteres
              </v-list-item-subtitle>
              <v-list-item-subtitle class="mb-2">
                {{ variavel.descricao }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <strong>Exemplo: </strong>
                {{ retornaExemploVariavel(variavel) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </div>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    abrirVariaveis: false,
    isEmail: false,
  },
  data: () => ({
    busca: "",
  }),
  computed: {
    ...mapGetters("variavelRegua", ["variaveis"]),
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
    variavelFiltrada() {
      return this.variaveis.filter((variavel) => {
        if (variavel.nome.toLowerCase().indexOf(this.busca.toLowerCase()) > -1)
          return true;
        return false;
      });
    },
  },
  methods: {
    concatenarVariavel(variavel) {
      this.$emit("concatenaVariavel", variavel);
    },
    retornaLarguraVariaveis() {
      if (this.isEmail) {
        return "326";
      } else {
        return "350";
      }
    },
    retornaExemploVariavel(variavel) {
      if (variavel.nome != "link_area_logada") {
        return variavel.exemplo;
      } else {
        if (variavel.exemplo[this.empresaSelecionada.chave]) {
          return variavel.exemplo[this.empresaSelecionada.chave];
        } else {
          return variavel.exemplo["padrao"];
        }
      }
    },
  },
  watch: {
    abrirVariaveis: {
      handler() {
        if (!this.abrirVariaveis) this.busca = "";
      },
    },
  },
};
</script>

<style scoped lang="scss">
.ml-variavel-mensagem {
  overflow-y: auto;
  overflow-x: hidden;
  .ml-nome-variavel {
    background-color: red !important;
    border-color: var(--v-primary-base);
    color: var(--v-primary-base);
  }
}

:v-deep(.v-dialog) {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%),
    0px 0px 0px 0px rgb(0 0 0 / 0%);
}
::-webkit-scrollbar {
  width: 4px;
  margin-left: 1px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 25px;
  padding-right: 0px;
}
</style>
